<template>
  <div>
    {{ type }}
  </div>
</template>
      
      <script>
      import {mapGetters } from 'vuex'
      export default {
          props: {object: {type: Object, required: true}},
          data(){
              return{
      
              }
          },
          methods: {
             
          },
          computed: {
              ...mapGetters({
                types: 'sanitaire/typeFicheAnalyses'
              }),
              type(){
                let c = this.types.find(item => item.uid === this.object.categorieAnalyse)
                if(c) return c.libelle
                return '-'
              }
          }
      }
      </script>
      
      <style>
      
      </style>